import Player from '@vimeo/player';

export default function(opts = {}) {

  return {
    quality: '720p',
    dim: null,
    player: null,
    iframeStyle: {
      width: 0,
      height: 0,
    },
    io: false,
    visible: true,
    ready: false,
    w: 16,
    h: 9,
    playing: false,
    options: null,

    init() {

      window.addEventListener('resize', () => this.resize());

      // Force resize for edge cases where video doesn't size properly on load
      setTimeout(() => { this.resize(); }, 300);

      this.dim = opts.dim ?? false;

      const options = {
        autoplay: false,
        id: this.id,
        muted: true,
        loop: true,
        controls: false,
        dnt: true,
        responsive: false,
        quality: '360p'
      };

      this.options = options;

      this.$watch('visible', newVal => {
        if ( !this.prefersReduced ) {
          if (newVal && !this.disabled) {
            this.play();
          } else {
            this.pause();
          }
        }
      });

      this.$watch('prefersReduced', newVal => {
        if (newVal) {
          this.pause();
        } else {
          this.play();
        }
      });

      this.$watch('a11yPlay', newVal => {
        if (newVal) {
          this.play();
        } else {
          this.pause();
        }
      });

      this.$watch('disabled', newVal => {
        if (!newVal && this.visible) {
          this.create();
        } else {
          this.destroy();
        }
      });

      this.create();
    },

    create() {
      const $el = this.$refs.iframe;
      this.player = new Player($el, this.options);

      if (!this.disabled && this.visible && !this.prefersReduced) {
        this.play();
      } else if (!this.disabled && this.visible && this.prefersReduced) {
        // If they prefer reduced motion, then show the video, but there
        // will be a play button
        this.readyVideo().then(() => {
          this.pause();
        });
      }
    },

    destroy() {
      window.removeEventListener('resize', this.resize);
      this.ready = false;
      if (this.player) {
        this.player.destroy().then(() => {
          this.player = false;
        });
      }
    },

    async readyVideo() {
        let player = Alpine.raw(this.player);
        await player.ready();
        this.w = await player.getVideoWidth();
        this.h = await player.getVideoHeight();
        this.ready = true;
        this.resize();
    },

    async play() {
      if ( !this.player ) {
        this.create();
      }
      this.readyVideo();
      Alpine.raw(this.player).play().then(() => this.playing = true).catch( () => {} );
    },

    pause() {
      if (this.player) {
        Alpine.raw(this.player).pause().then(() => this.playing = false).catch( () => {} );
      }
    },

    resize() {
      const container = this.$root;
      const w = container.offsetWidth;
      const h = container.offsetHeight;
      const windowRatio = h / w;

      if (windowRatio > this.ratio) {
        this.iframeStyle.width = `${h * (this.inverseRatio)}px`;
        this.iframeStyle.height = `${h}px`;
      } else {
        this.iframeStyle.width = `${w}px`;
        this.iframeStyle.height = `${w * (this.ratio)}px`;
      }
    },

    get ratio() {
      return this.h / this.w;
    },

    get inverseRatio() {
      return this.w / this.h;
    },

    get disabled() {

      // Force pause
      if (this.debug) {
        return true;
      }

      if (this.overlayShowing) {
        return true;
      }

      return false;
    }
  }

}